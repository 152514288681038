.section {
    padding: 0.5rem;
    padding-bottom: 0;
}

.spanTwo {
    grid-column: span 2;
}

.spanFour {
    grid-column: span 4;
}

.spanEight {
    grid-column: span 8;
}

.mobileFull {
    grid-column: span 4;
    @media(min-width: 768px) {
        grid-column: span 2;
    }

}

.containerHeader {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(209, 209, 209, 0.25);
    padding: .5rem;
}

.paymentContainer {
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1);
    padding: 0rem 0rem 0.5rem 0rem;
    margin-bottom: 0.5rem;
   
}

.addPayment {
    padding: .5rem;
    display: flex;
    justify-content: flex-end;
}

.formGrid {
	display: grid;
	margin: 0px 0px;
	font-size: 1rem;
	grid-template-columns: repeat(4, 1fr);
	gap: 0.1rem 0.5rem;
	@media (min-width: 767px) {
		grid-template-columns: repeat(8, 1fr);
		margin: 0.1rem 1rem;
	}
	@media (min-width: 1200px) {
		grid-template-columns: repeat(8, 1fr);
	}
	[class~="slds-form-element__label"] {
		color: rgb(175, 175, 175);
	}
	input:read-only {
		font-size: 1rem;
	}
}