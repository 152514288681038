.container {
    font-size: 1rem;
    padding: .5rem;

    .submitButtons {
        display: grid;
        justify-self: center;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        max-width: 400px;
        position: sticky;
    }

    .optionsContainer {
        display: flex;
        flex-direction: column;

    }
}