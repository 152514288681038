@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	/* margin-right: 0.25rem; */
	animation: spin 2s ease-in-out infinite;
}
