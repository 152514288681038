.Form {
	display: flex;
	background-color: white;
}

.sldsTextArea {
	.textArea {
		label {
			font-weight: bold;
		}
		width: 100%;
		min-height: 100px;
	}
}

.expandableRecipients {
	.sldsElement {
		@media (min-width: 500px) {
			margin: 0 10px;
		}
	}
}
.sldsElement {
	padding: 0px;
}
.pictures {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	.picture {
		min-width: 18em;
		margin-bottom: 10px;
		[class~="slds-file__crop"] {
			img {
				max-width: 100%;
			}
		}
	}
}

.emailActions {
	padding-top: 10px;
	display: flex;
	justify-content: center;
}
