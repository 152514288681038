.errorContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: .5rem;
}

.bodyContainer {
    background-color: white;
    display: grid;
    gap: .5rem;
    padding: .5rem;
}