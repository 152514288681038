.StaticInput {
	color: black;
	display: flex;
	height: 100%;
	width: 100%;
	align-items: flex-end;
	[class~="slds-input"] {
		font-size: 1rem;
		border-bottom: 1px solid #dddbda;
		border-radius: 0px;
		width: 100%;
	}
	[class~="slds-form-element"] {
		width: 100%;
	}
	[class~="slds-button__icon"] {
		fill: unset;
	}
}
