.card {
    padding: 0.5rem;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: .5rem;
    label {
        color: #3e3e3c;
        font-size: .75rem;
    }
    p {
        font-size: .8rem;
    }
    .row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: .25rem;
    }
    margin-bottom: .5rem;
}

.container {
    padding-top: 0.5rem;
}


.icon {
    width: 20; 
    height: 20;

}
