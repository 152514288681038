.input {
	align-self: center;
	text-align: end;
}

.readOnly {
	padding-right: 1rem;
}

.container {
	display: flex;
	justify-content: flex-end;
	padding: 0.25rem 0;
}
