.noWrap {
	display: flex;
	overflow-x: auto;
	padding-bottom: 0.5rem;
}

.wrap {
	display: flex;
	flex-wrap: wrap;
	height: 100vh;
	overflow: auto;
}

.splitView {
	.FilterPanel {
		top: 4rem;
	}
	.hasFilters {
		top: 5.5rem;
	}
}

.FilterPanelWrapper {
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	position: relative;
}

.FilterPanelWrapperOpen {
	@media (min-width: 768px) {
		grid-template-columns: minmax(0, 1fr) 20rem !important;
	}
}

.FilterPanel {
	margin-left: 0em;
	margin-bottom: 20em;
	@media (min-width: 768px) {
		position: sticky;
		top: 4rem;
	}
	padding: 0 0.25rem;
}

.PageHeader {
	padding: 0.5rem;
	background-color: #ffffff;
	border: none;
	border-radius: 0;
	border-bottom: 1px solid #dddbda;
	box-shadow: none;
	position: relative;

	z-index: 50;
	[class~="slds-page-header__title"] {
		font-size: 1.5rem;
		color: black;

		@media screen and (max-width: 678px) {
			font-size: 1rem;
		}
	}
}

.sticky {
	position: sticky;
	top: calc(0rem - 1px);
}

.pageTopFix {
	z-index: 70;
	top: calc(3rem - 1px);
}

.web {
	z-index: 80;
}
.filterPageTopFix {
	top: 7rem;

	&.hasFilters {
		top: 8.5rem;
	}
}

.contentWrapper {
	position: relative;
	// padding: 0.5rem 2px;
	padding: 3px;
	// border: 1px solid transparent;
	// border-bottom: 1px solid #dddbda;
	border-radius: 0;
	box-shadow: inset 0 0 2px #bbb;
	box-sizing: border-box;
}

.draggedOver {
	border: 3px dashed lightblue;
	box-shadow: none;
	padding: 0;
	// padding: calc(0.5rem - 2px) 0;
}
