.container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: .5rem;
    padding: .5rem;
}

.footer {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: .5rem;
}



.message{
    color: blue;
}

.address{
    grid-column: 1 / span 2;
}