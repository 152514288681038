.container {
    display:grid;
    grid-template-columns: 1fr;
    gap: .5rem;
    padding: 1rem;
    align-items: center;
    .radio {
        cursor: pointer;
        width: 100%;
        padding: 0rem .5rem;
        text-align: left;
        display: grid;
        gap: .2rem;
        @media(min-width: 768px) {
            grid-template-columns: 1fr 1fr ;
        }
        grid-template-columns: 1fr 1fr;    
        .addressContainer {
            grid-column: 1 / span 2;
        }
    }
    .address {
        display: flex;
        flex-direction: column;
    }
    .fieldLabel{
      color: rgb(72, 72, 72);    
      font-size: x-small;
    }
    [class~='slds-form-element__control'] {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        @media(min-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }
        @media(min-width: 1068px) {
            grid-template-columns: 1fr 1fr  1fr 1fr;
        }
        width: 100%;
        [class~='slds-visual-picker__figure'] {
            width: 100%;
        }
        [class~='slds-visual-picker'] {
            margin: 0;
        }
        [class~='slds-visual-picker__body'] {
            display: none;
        }
    }
}