.container {
	[class~="slds-notify_toast"] {
		min-width: 300px;
	}
	.updateButton {
		color: white;
		background-color: rgba(255, 255, 255, 0);
		padding: 0.1rem;
		margin-left: 1rem;
	}
}
