.gridItem {
	grid-column: 1 / span 2;
	@media (min-width: 767px) {
		grid-column: 1 / span 4;
	}
}

.controlledPriceContainer {
	display: grid;
	grid-template-columns: 5fr 1fr;
	.price {
		display: flex;
		justify-content: center;
	}
}

.skipButton {
	align-self: flex-end;
	max-height: 32px;
	height: min-height;
	margin-top: 0.5rem;
	@media (min-width: 767px) {
	}
}

.controlledMiscellaneousContainer {
	display: grid;
	grid-template-columns: 10fr 1fr;
	box-shadow: 1px 1px 1px 1px rgba(128, 128, 128, 0.06);
	padding-bottom: 1rem;
}

.miscContainer {
	@media (min-width: 767px) {
		margin: 0.1rem 1rem;
	}
}
