.webTab {
	display: flex;
	flex-direction: column;
	justify-content: center;
	h2 {
		font-size: medium;
		padding-bottom: 0.5rem;
	}
	.error {
		color: red;
	}

	.textArea {
		padding: 0.5rem;
		margin-bottom: 0.5rem;
		font-size: medium;
		border-radius: 0.5rem;
		resize: vertical;
		width: 100%;
		min-height: 10vh;
		overflow-y: hidden; /* Hide vertical scrollbar */
		border: 1px solid #cbd5e0;
	}

	.buttonContainer {
		padding: 1rem 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		gap: 0.5rem;
		flex-direction: row;
	}

	.button {
		width: 6rem;
	}
}

.promptText {
	padding: 0.5rem;
	width: 100%;
	resize: vertical;
	min-height: 30vh;
	border-radius: 0.5rem;
	border: 1px solid #cbd5e0;
	margin-bottom: 0.5rem;
}

.descriptionContainer {
	margin: 1rem;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	/* margin-right: 0.25rem; */
	animation: spin 2s ease-in-out infinite;
	fill: #0070d2;
}
