.Toolbar {
	display: flex;
	grid-auto-flow: column;
	width: 100%;
	min-width: 100%;
	height: 3rem;
	position: sticky;
	top: 0;
	left: 0;
	background-color: white;
	justify-content: space-between;
	align-items: center;
	padding: 0 0.1rem;
	box-sizing: border-box;
	z-index: 90;
	flex-flow: nowrap;
	position: relative;
	padding: 0 0.75rem;

	.HomeBaseLogo {
		width: 10rem;
	}

	.MenuButton {
		position: relative;
	}

	.ProfileDropdown {
		float: left;
		overflow: hidden;
		.DropdownButton {
			border: none;
			display: block;
			outline: none;
			margin: 0;
			background-color: inherit;
		}
	}

	.ProfileIcon {
		height: 100%;
		padding-right: 0.5rem;
		button {
			height: 100%;
		}
	}

	.ProfileDropdown:hover .ProfileDropdown-content {
		display: block;
	}
}

.BadgeContainer {
	z-index: 100;
	position: absolute;
	right: -0.5rem;
	top: 1rem;
}
