.title {
	margin-right: 1em;
	h3 {
		color: white;
		font-size: 1.5em;
		&:hover {
			cursor: pointer;
		}
	}
}
.editButtons {
	flex-grow: 1;
	align-self: flex-end;
}

.toolbar {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.tagsContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 0.25rem;
	align-items: center;
}

.editShareContainer {
	display: flex;
	position: absolute;
	bottom: 0;
	right: 0.25rem;
	a {
		padding: 5px;
	}
	button {
		padding-bottom: 0px;
	}
}

.editingContainer {
	display: flex;
	align-items: flex-start;
	margin-bottom: 100px;
}

.toggle {
	display: inline-block;
	margin-left: 0.5rem;
	label span {
		color: white;
	}
}

.slider {
	vertical-align: middle;
	align-items: center;
	color: white;
	input {
		vertical-align: middle;
		margin-right: 0.5rem;
	}
	[class~='slds-slider__value'] { 
		display: none;
	}
}

.numberInput {
	button {
		color: #fff;
		&:focus,
		&:hover {
			color: rgba(255, 255, 255, 0.75);
		}
	}
}

.titleInput {
	display: inline-block;
	vertical-align: middle;
	[class~="slds-input"] {
		background-color: transparent;
		color: white;
		&:focus, &:active {
			border-color: white;
			box-shadow: var(--sds-c-input-shadow-focus,0 0 3px white);
		}
	}
}

.tagsDropdown {
	background-color: transparent;
	border: none !important;
	box-shadow: none;
	min-width: auto;
	height: 100%;
	display: flex;
	a {
		// border: none;
		padding: .1rem;
		&:hover, &:active, &:first-child {
			background-color: transparent;
		}
	}
}
.tagsAddButton {
	height: 100%;
	vertical-align: 'middle';
	margin-left: 2px;
}