.Container {
    background-color: white;
    display: grid;
    gap: 5px;
    min-height: 100px;
    align-items: center;
    grid-template-columns: 12rem 1fr;
    @media (max-width: 768px) {
        align-items: unset;
        display: flex;
        flex-direction: column;
    }
    padding: .5rem;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.285);
    .info {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .text {
        word-break: break-all;
    }

}
.SideView {
    align-items: unset;
    display: flex;
    flex-direction: column;
}

.source {
    color: black;
    font-weight: bold;
}

.remarketing {
    color: blue;
}

.legal {
    color: rgb(187, 122, 0);
}

.recovery {
    color: purple
}

.collections {
    color: brown;
}

.vantage {
    color: seagreen;
}