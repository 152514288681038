.insurance {
	&-card {
		padding: 0px;
		display: grid;
		grid-template-columns: 1fr 1fr 0.4fr 30px;
		place-content: center;
		grid-template-rows: 15% 40px;
		min-height: 200px;
		// padding-left: 1rem;
	}

	&-account {
		font-weight: bolder;
		margin-bottom: 0;
		font-size: 14px;
		display: flex;
		flex-direction: row;
		padding-left: 1rem;
		justify-content: space-between;
		&-icon-and-number {
			display: flex;
			flex-direction: row;
		}
		&-number {
			margin-left: 8px;
			font-size: large;
		}
	}

	&-name {
		display: inline-block;
		color: #555;
		font-weight: bold;
		text-transform: capitalize;
	}

	&-info {
		grid-row: 1 / 1;
		grid-column: 1 / 4;
	}

	&-status-1 {
		grid-column: 1 / 4;
		grid-row: 2/2;
		display: flex;
		justify-content: space-between;
		margin-top: 0.5rem;
		padding-left: 1rem;

		li label {
			text-transform: uppercase;
			color: gray;
		}
	}
	&-status-2 {
		grid-column: 1 / 4;
		grid-row: 3;
		display: flex;
		justify-content: space-between;
		margin-top: 0.5rem;
		padding-left: 1rem;

		li label {
			text-transform: uppercase;
			color: gray;
		}
	}
	&-decision {
		padding-left: 1rem;
		grid-column: 1 / 4;
		grid-row: 4;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		&-radio {
			width: 100%;
		}
	}
}
