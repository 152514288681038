.wrapper {
	position: absolute;
	top: 1.8rem;
	left: 12rem;
}

.mobileWrapper {
	margin-left: 3rem;
}

.alignMiddle {
	vertical-align: middle;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	margin-right: 0.25rem;
	animation: spin 2s ease-in-out infinite;
}
