.container {
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: #fafaf9;
	justify-content: flex-end;
	padding: 0.25rem 0rem;
	border-radius: 5px 5px 0px 0px;

	button {
		background-color: rgb(0, 89, 225, 0.01);
		border-radius: 20rem;
		border: none;
		padding: 0.25rem;
		outline: none;
		margin-right: 0.25rem;
		svg {
			height: 1rem;
			width: 1rem;
		}
	}
	button:hover {
		svg {
			fill: rgb(0, 89, 255);
		}
	}
	button:disabled {
		svg {
			fill: grey;
		}
	}
}
