.notFavorited {
	margin-left: 0.25rem;

	box-shadow: none !important;
	svg {
		fill: none !important;
		stroke: black;
		stroke-width: 1px;
	}

	&:hover {
		svg {
			fill: rgb(7, 124, 233);
		}
	}

	&:disabled {
		svg {
			fill: none;
		}
	}

	&:active {
		fill: none !important;
		stroke: rgb(7, 124, 233) !important;
		stroke-width: 1px;
		color: unset;
	}
}

.favorited {
	margin-left: 0.25rem;
	box-shadow: none !important;
	svg {
		fill: rgb(7, 124, 233);
	}

	&:disabled {
		svg {
			fill: rgb(7, 124, 233) !important;
			opacity: 0.9;
		}
	}

	&:active {
		svg {
			fill: rgb(7, 124, 233);
		}
	}
}

.favoriteButton {
	border: none;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	svg {
		animation: spin 2s ease-in-out infinite;
	}

	box-shadow: none !important;
}
