.mobile-table {
	&-card {
		position: relative;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 0.5fr 1fr 30px;
		place-content: center;
		cursor: pointer;
		height: min-content;
		padding: 1rem 0.5rem 1rem 0.5rem;
		border: 1px solid #dddbda;
		border-radius: 0.25rem;
		margin-bottom: 0.25rem;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
	}
	&-account {
		font-weight: bolder;
		margin-bottom: 0;
		a {
			padding: 0.5rem 0.5rem 0.5rem 0rem;
		}
		font-size: 14px;
		text-transform: uppercase;
	}

	&-name {
		display: inline-block;
		color: #555;
		font-size: 14px;
		font-weight: bold;
		margin-left: 0.5rem;
		text-transform: uppercase;
	}

	&-info {
		grid-row: 1 / 2;
		grid-column: 1 / 3;
	}

	&-address {
		margin: 0;
		text-transform: capitalize;
		color: #222;
		span {
			width: 100%;
		}
	}

	&-status {
		grid-column: 3 / 4;
		grid-row: 1/1;
		display: flex;
		justify-content: space-between;

		li label {
			text-transform: uppercase;
			color: gray;
		}
	}

	&-res {
		display: inline-block;
		color: #414141;
		font-weight: bold;
		text-transform: capitalize;
		padding-right: 0.5rem;
	}

	&-lastInspection {
		grid-column: 3 / 4;
		grid-row: 2/2;
		display: flex;
		margin-top: 0.5rem;
		justify-content: space-between;

		li label {
			text-transform: uppercase;
			color: gray;
		}
	}

	&-warningSign {
		fill: #ffcc4d;
	}

	&-dates {
		grid-column: 1 / 4;
		grid-row: 2 /3;

		display: flex;
		justify-content: space-between;
		margin-top: 0.5rem;

		li label {
			text-transform: uppercase;
			color: gray;
		}
	}

	&-indicators {
		grid-row: 1 / 2;
		grid-column: 3 / 4;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-end;

		.indicator {
			text-align: center;
			width: 100%;
			border-radius: 999999px;
			padding: 0;

			&__green {
				background-color: lightgreen;
			}

			&__yellow {
				background-color: lighten(yellow, 20%);
			}

			&__red {
				background-color: lighten(red, 20%);
			}
		}
	}

	&-actions {
		grid-column: 4 / 4;
		grid-row: 1 / 1;
		display: flex;
	}

	&-offline {
		grid-column: 1/5;
		grid-row: 5 / 5;
		display: flex;
		flex-direction: column;
		margin: 0.5rem 0rem 0rem 0rem;
		&_available {
			button {
				color: #027e46 !important;
			}
		}
	}

	.icon-wrapper {
		height: 44px;
		width: 44px;
	}
}
