.locationMapContainer {
	display: flex;
	height: min-content;
	overflow-y: hidden;
	[class~="slds-map"] {
		min-width: none;
	}
}
.locationMap {
	align-content: center;
	max-height: 45vh;
	min-width: 16rem;
}
