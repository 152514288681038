.mapContainer {
	height: 58vh;
	width: 100%;
	@media (min-width: 768px) {
		height: 60vh;
	}
}

.info {
	background-color: #fafaf9;
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
	padding: 0.5rem;
	border-radius: 5px 5px 0px 0px;
	.infoContainer {
		display: grid;
		grid-template-columns: min-content min-content 1fr;
		gap: 0.5rem;
	}
}

.rowStyle {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	p {
		white-space: nowrap;
	}
}
