.nameInput {
	input {
		border-radius: 0.25rem 0px 0px 0.25rem;
		margin: 0px;
		width: 100%;
		min-width: 200px;
		max-width: unset;
	}
}

.save {
	border-radius: 0px 0.25rem 0.25rem 0px;
	margin: 0px;
	width: 100%;
}

.valueWrapper {
	ul {
		li {
			padding-left: 0rem;
			[class~="slds-pill__label"] {
				padding: 0.15rem;
			}
		}
	}
}

.filterButtons {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;

	[class~="slds-button"] {
		margin-bottom: 0.25rem;
		margin-left: 0px;
	}
}
