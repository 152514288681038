.searchResultContainer {
    max-height: 50vh;
    overflow-y: scroll;
    padding-top: .5rem;
    display: grid;
    @media( min-width: 768px) {
        gap: .5rem;
        grid-template-columns: 1fr 1fr;
    }   
    .selected {
        position: relative;
        cursor: pointer;
    }
}

.submitButtons {
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	/* margin-right: 0.25rem; */
	animation: spin 2s ease-in-out infinite;
}

.pageNumbers {
    padding: 1rem .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        padding: 0rem 1rem;
    }
    button {
        background-color: none;
        border: none;
    }
}

.selectedCheckmark {
    border: 2px solid #0070d2;
    border-top-right-radius: 4;
    background-color: white;
    width: 30;
    height: 30;
    position: absolute;
    top: 0;
    right: 0;
    color: #0070d2;
}
