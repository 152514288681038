.formContainer {
	padding: 1rem;

	.formGrid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 0.5rem;
		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}

		.spanTwo {
			grid-column: span 2;
		}
		.dateInput {
			height: 32px;
			line-height: unset;
		}
	}
}

.phoneNumberInputContainer {
	display: flex;
	flex-direction: column;
}

.submitButtons {
	display: flex;
	justify-content: center;
	padding-bottom: 1rem;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	/* margin-right: 0.25rem; */
	animation: spin 2s ease-in-out infinite;
}
