.Content {
    position: relative;
    width: 100%;
    @media(min-width: 768px) {
        top: 3.1rem;
    }
}

.AssetListContainer {
    [class~="slds-box"] {
        @media(max-width: 768px) {
            border-radius: 0;
        }
    }
}
.CustomBrandBand {
	@media (min-width: 768px) {
		padding: 0.75rem;
        height: 20vh;
        background-color: unset;
        background-image: url("banner-brand-default.png"),
        linear-gradient(to top, rgba(245, 245, 245, 0) 0, #1b5f9e);
	}
	width: 100%;
}
.CustomBrandBand:after {
    background-image: url("banner-brand-default.png"),
	linear-gradient(to top, rgba(245, 245, 245, 0) 0, #1b5f9e);
    height: 20vh;
}