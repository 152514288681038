.container {
	padding: 0.2rem 0.5rem;
	h2 {
		font-size: 1rem;
		margin-bottom: 0.2rem;
	}
	[class~="slds-radio"] {
		cursor: pointer;
	}
}
