.mobile {
	width: 100%;
	border: none;
	border-radius: none;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	margin-right: 0.25rem;
	animation: spin 2s ease-in-out infinite;
}

.offline {
	stroke: #027e46 !important;
	fill: #027e46 !important;
}

.modalText {
	padding: 1rem;
	text-align: center;
	}