.mediaCard {
	display: block;
	user-select: none;
}

.checkbox {
	align-self: center;
}

.card {
	border: none;
	box-shadow: 0.1rem 0.1rem 0.2rem #777;
	margin: 0.25rem;
	@media (min-width: 500px) {
	}
	[class~="slds-file__title"] {
		top: 0;
		color: black;
		bottom: initial;
		border: 1px solid #ddd;
		border-bottom: none;
	}
	[class~="slds-file__crop"] {
		img {
			padding-top: 2.2rem;
			max-width: 100%;
			max-height: 100%;
		}
	}
	a:hover:after {
		top: 67% !important;
	}

	[class~="slds-file__actions-menu"] {
		top: 0.3rem !important;
		bottom: initial;
	}
}
