.formContainer {
    display: grid;
    gap: .5rem;
    padding: 1rem;
    grid-template-columns: 1fr;
    min-height: 15rem;
}

.draggedOver {
    border: 1px blue dashed;
}

.notDraggedOver {
    border: 1px rgba(0, 0, 0, 0.25) dashed;
}

.dropContainer {
    height: 100%;
    width: 100%; 
    display: flex;
    min-height: 20rem;
    justify-content: center; 
    align-items: center;
    flex-direction: column;
    grid-column: span 2;
}

.dynamicIcon {
    padding: 0;
}