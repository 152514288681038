.conditionReportContent {
	display: flex;
	flex-flow: row wrap;

}
.lineItem {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: .2rem;
	margin: .2rem;

	width: 100%;
	padding: .5rem;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.081);
	.item {
		display: flex;
		flex-direction: column;
		.label {
			font-weight: bold;
		}
	}
	@media(min-width: 768px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

