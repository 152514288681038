.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px;
    border: .01rem solid rgba(0, 0, 0, 0.186);
    background-color: white;
    padding: .5rem;
    font-size: x-small;
    .buttonContainer {
        width: 15rem;
    }
    .buttonContent {
        overflow: hidden;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis;
        color: blue;
    }

    .firstRow {
        width: 100%;
        [class~="slds-icon_container"] {
            margin-right: 1px;
        }
        display: flex;
        flex-direction: row;
        button {
            text-overflow: ellipsis;
        }
    }
}