.worksheet {
	[class~="slds-section__title-action"] {
		padding: 0.5rem;
		border-radius: 0.5rem;

		&:focus {
			box-shadow: none;
		}
	}

	[class~="slds-table--cell-buffer"],
	[class~="slds-table_cell-buffer"] {
		tr > td:first-child,
		tr > th:first-child {
			padding-left: 0.5rem;
		}
		tr > td:last-child,
		tr > th:last-child {
			padding-left: 0.5rem;
		}
		tr > td,
		tr > th {
			input {
				min-height: unset;
			}
		}
	}

	[class~="slds-table"] thead th {
		&:not(:first-child) {
			text-align: right;
			width: unset;
		}
	}

	h3 {
		border-bottom: 0;
	}

	[class~="slds-section__content"] {
		margin: 0 1px 0 1px;
		border-top: 0;
		background-color: #f3f2f2;
		padding-top: 0 !important;
	}

	margin: 1rem;
}

.worksheetOpen {
	[class~="slds-section__title-action"] {
		border-radius: 0.5rem;
		border-bottom: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		&:focus {
			box-shadow: none;
		}
	}

	[class~="slds-section__content"] {
		overflow: scroll !important;
		margin: 0 1px 0 1px;
		border-top: 0;
		background-color: #f3f2f2;
		padding: 1rem;
		background-color: #f3f2f2;
		border-radius: unset;
		border-bottom-right-radius: 1rem;
		border-bottom-left-radius: 1rem;
	}
}

.formContainer {
	background-color: white;
	padding: 0 1rem;
	width: 100%;

	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
		appearance: none;
		margin-left: 1rem;
	}
	input[type="number"] {
		-moz-appearance: textfield;
	}
}

.statusLinksContainer {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0 1rem;

	button {
		height: 2rem;
	}
}

.status {
	border: 1px solid;
	padding: 2px 4px;
	border-radius: 4px;
	height: fit-content;
	color: #2e844a;
	margin-left: 0.5rem;
	font-size: 0.8rem;
}

.worksheetFieldContainer {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 0.1rem 0.5rem;
	padding: 0.25rem 1rem;
}

.miscEstTitle {
	background-color: transparent;
	text-align: end;
	font-weight: bold;
	align-self: end;
	border: none;

	&:active {
		outline: none;
	}
	&:focus-visible {
		outline: none;
	}
}

.columnHeaders {
	background-color: #fafaf9;
	th {
		font-weight: bold;
		font-size: 0.85rem;

		&:not(:first-child) {
			text-align: end;
		}
	}
}

.pointer {
	cursor: pointer;

	select:hover {
		cursor: pointer;
	}
}

.recoveryRow {
	font-weight: bold;
	font-size: 0.85rem;
}

.worksheetTitle {
	display: flex;
	align-items: center;
	background: #f3f2f2;
	gap: 0.5rem;
	color: currentColor;
	font-size: 0.8rem;
	padding: 0.5rem 0.5rem;
	margin: 0 1rem 0 1rem;

	padding: 1rem 0.5rem 0 0.5rem;

	border-radius: 0.5rem;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;

	h2 {
		border-bottom: 0;
		display: flex;
		align-items: center;
		text-align: left;
	}
}

.worksheetTable {
	overflow: scroll !important;
	margin: 0 1rem 1rem 1rem;
	border-top: 0;
	background-color: #f3f2f2;
	padding: 1rem;
	background-color: #f3f2f2;
	border-radius: unset;
	border-bottom-right-radius: 1rem;
	border-bottom-left-radius: 1rem;
}

.toolTipHover {
	width: 350px;

	.historyWrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		height: 30px;
		align-items: center;
	}

	.closeButton {
		position: absolute;
		top: -0.25rem;
		right: 0.25rem;
		justify-content: flex-end;
	}

	.title {
		text-align: center;
		font-size: 0.9rem;
	}

	thead tr {
		font-weight: bold;
	}

	tr {
		width: 100%;
	}
	th,
	td {
		padding: 0.5rem;
	}

	.alignRight {
		text-align: right;
	}
}
